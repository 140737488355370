import React from 'react';
import { Container, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import SingleRow from './SingleRow';

export default function TrackingTable({ tracks }) {
    return (
        <Container>
            <Grid container spacing={5}>
                <Grid item xs={12} sm={12} md={12}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Deal</TableCell>
                                    <TableCell>Po Date</TableCell>
                                    <TableCell>Supplier</TableCell>
                                    <TableCell>Tracks</TableCell>
                                    <TableCell>Expected Delivery Date</TableCell>
                                    <TableCell>Last Update Date</TableCell>
                                    <TableCell>Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tracks && tracks.map((track, index) => (
                                    <SingleRow key={index} track={track} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Container>
    );
}