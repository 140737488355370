import React, { useState, useEffect } from 'react';
import {
    Button,
    Grid,
    Box,
    Collapse,
    Container,
    Tabs,
    Tab,
    TextField,
    InputAdornment,
    IconButton,
    Dialog, DialogTitle, DialogContent, DialogActions, Typography
} from '@mui/material';
import Header from "../Header";
import Tasks from "../Tasks";
import { v4 as uuidv4 } from "uuid";
import TaskFields from "./TaskFields";
import { toast } from "react-toastify";
import AddItemsToTask from "./AddItemsToTask";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DocsEditor from "../DocsEditor";
import {useNavigate} from "react-router-dom";
import TuneIcon from "@mui/icons-material/Tune";
import CloseIcon from "@mui/icons-material/Close";


export default function TaskPage(){
    const baseRoute = process.env.REACT_APP_BASE_ROUTE;
    const apiToken = localStorage.getItem('token');
    const perPage = 10;
    const taskPerPage = 10;

    // Состояния компонента
    const [loggedIn, setLoggedIn] = useState(true);
    const [isLoggedOut, setIsLoggedOut] = useState(false);
    const [taskUUID, setTaskUUID] = useState(uuidv4());
    const [itemUUID, setItemUUID] = useState(uuidv4());
    const [isTaskFieldsOpen, setTaskFieldsOpen] = useState(false);
    const [tasks, setTasks] = useState([]);
    const [taskItemsResp, setTaskItemsResp] = useState([]);
    const [stockItemsResp, setStockItemsResp] = useState([]);
    const [taskDocs, setTaskDocs] = useState([])
    const [isDocsCreated, setDocsCreated] = useState(false)
    const [routes, setRoutes] = useState([]);
    const [values, setValues] = useState({});
    const [partNumberFilter, setPartNumberFilter] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [currentTaskPage, setCurrentTaskPage] = useState(1);
    const [originStockID, setOriginStockID] = useState('');
    const [selectedItems, setSelectedItems] = useState([])
    const [selectedOrders, setSelectedOrders] = useState([]);
    const [updatedItems, setUpdatedItems] = useState([])
    const [isRouteChangeable, setRouteChangeable] = useState(true);
    const [refresh, setRefresh] = useState(true)
    const [taskFields, setTaskFields] = useState({
        task_id: '',
        task_num: '',
        route_id: '',
        route_name: '',
        task_type: '',
        task_date: null,
        task_state: '',
    });
    const [taskStateFilter, setTaskStateFilter] = useState("");
    const [isDocsEditorOpen, setDocsEditorOpen] = useState(false)
    const [value, setValue] = useState(0);
    const [on, setOn] = useState(false)
    const [taskNumFilter, setTaskNumFilter] = useState('');
    const [poNumFilter, setPoNumFilter] = useState('');
    const [partNumFilter, setPartNumFilter] = useState('');
    const [taskType, setTaskType] = useState("internal")
    const [taskTypeVal, setTaskTypeVal] = useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue);
        switch(newValue) {
            case 0:
                setTaskStateFilter("");
                break;
            case 1:
                setTaskStateFilter("preparing");
                break;
            case 2:
                setTaskStateFilter("complectating");
                break;
            case 3:
                setTaskStateFilter("in_transit");
                break;
            case 4:
                setTaskStateFilter("completed");
                break;
            default:
                setTaskStateFilter("");
        }
    };

    const handleTaskTypeChange = (event, newValue) => {
        setTaskTypeVal(newValue)
        switch(newValue) {
            case 0:
                setTaskType("internal")
                setTaskNumFilter("")
                break
            case 1:
                setTaskType("external")
                setTaskNumFilter("")
                break
            default:
                setTaskType("internal")
        }
    }

    const handleFilterChange = (event) => {
        console.log(event.target.name, event.target.value);
        if (event.target.name === 'poNumFilter') {
            setPoNumFilter(event.target.value);
        }
        if (event.target.name === 'partNumFilter') {
            setPartNumFilter(event.target.value);
        }
        if (event.target.name === 'taskNumFilter') {
            setTaskNumFilter(event.target.value);
        }
    };

    // Обработчик выхода из системы
    const handleLogout = () => {
        localStorage.removeItem('token');
        setLoggedIn(false);
        setIsLoggedOut(true);
    };

    const handleGoBack = () => {
        if (isTaskFieldsOpen) {
            setTaskFieldsOpen(false)
            setTaskUUID(uuidv4());
            setOriginStockID('')
        } else if (isDocsEditorOpen){
            setDocsEditorOpen(false)
            setTaskFieldsOpen(true)
        }
    };

    // Обработчик изменения страницы stock items
    const handlePageChange = (event, page) => {
        setCurrentPage(page <= 0 ? 1 : page);
    };

    // Обработчик изменения страницы task items
    const handleTaskPageChange = (event, page) => {
        setCurrentTaskPage(page <= 0 ? 1 : page);
    };

    // Обработчик изменения полей задачи
    const handleTaskFieldsChange = async(updatedData) => {
        try {
            setTaskFields((prevTaskFields) => ({
                ...prevTaskFields,
                route_id: updatedData.route_id,
                route_name: updatedData.route_name
            }))
            const savedTask = await saveTask(updatedData.route_id);
            console.log(savedTask);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        const fetchTasksData = async () => {
            try {
                let url = `${baseRoute}/api/v1/tasks/${taskType === "internal" ? "internal" : "external"}`;

                const params = new URLSearchParams();

                if (taskStateFilter) {
                    params.append('task_state_filter', taskStateFilter);
                }
                if (poNumFilter) {
                    params.append('po_number_filter', poNumFilter);
                }
                if (partNumFilter) {
                    params.append('part_number_filter', partNumFilter);
                }

                if (taskNumFilter) {
                    params.append('task_num_filter', taskNumFilter);
                }

                url = `${url}?${params.toString()}`;

                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': apiToken,
                    }
                });

                if (!response.ok) {
                    toast("Error fetch tasks", {
                        position: "top-center",
                        autoClose: 3000,
                        type: "error",
                    });
                    return;
                }

                const data = await response.json();
                const tasks = data.tasks
                const sortedTasks = tasks.sort((a, b) => b.task_num - a.task_num);
                setTasks(sortedTasks);
            } catch (error) {
                console.error("Error fetching tasks:", error);
            }
        };

        fetchTasksData().catch((error) => {
            console.error("Error fetching tasks:", error);
        });
    }, [isTaskFieldsOpen, apiToken, baseRoute, taskStateFilter, poNumFilter, partNumFilter, taskType, taskNumFilter]);


    // Загрузка маршрутов при монтировании компонента
    useEffect(() => {
        const fetchRoutesData = async () => {
            try {
                const response = await fetch(`${baseRoute}/api/v1/routes/internal`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': apiToken,
                    }
                });

                if (!response.ok) {
                    toast("Error fetch routes", {
                        position: "top-center",
                        autoClose: 3000,
                        type: "error",
                    });
                    return
                }

                const data = await response.json();
                setRoutes(data.routes);
            } catch (error) {
                throw error
            }
        };

        fetchRoutesData().catch((error) => {
            console.error("Error fetching routes:", error);
        });
    }, [apiToken, baseRoute]);

    // Загрузка элементов задачи при открытии полей задачи
    useEffect(() => {
        if (isTaskFieldsOpen) {
            const fetchTaskItems = async () => {
                try {
                    const queryParams = new URLSearchParams();
                    if (partNumberFilter) {
                        queryParams.append('part_number_filter', partNumberFilter)
                    }
                    queryParams.append('page', currentTaskPage.toString())
                    queryParams.append('per_page', taskPerPage.toString())
                    const response = await fetch(`${baseRoute}/api/v1/taskItems/byTaskID/${taskFields.task_id}?${queryParams.toString()}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': apiToken,
                        }
                    });

                    if (!response.ok) {
                        toast("Error fetch task items", {
                            position: "top-center",
                            autoClose: 3000,
                            type: "error",
                        });
                        return
                    }

                    const resp = await response.json();
                    setTaskItemsResp(resp);
                } catch (error) {
                    throw error
                }
            };
            fetchTaskItems().catch((error) => {
                console.error("Error fetching task items:", error);
            });
        }
    }, [isTaskFieldsOpen ,taskFields.task_id, currentTaskPage, partNumberFilter, refresh, apiToken, baseRoute]);

    // Загрузка элементов склада при изменении параметров поиска
    useEffect(() => {
        if (originStockID !== "" && isTaskFieldsOpen) {
            const fetchStockItemsData = async () => {
                try {
                    let params = new URLSearchParams();
                    if (values.po_num_filter) {
                        params.append('po_number_filter', values.po_num_filter)
                    }
                    if (values.part_number_filter) {
                        params.append('part_number_filter', values.part_number_filter)
                    }

                    params.append('route_id', taskFields.route_id)
                    params.append('stock_id', originStockID)
                    params.append('page', currentPage.toString())
                    params.append('per_page', perPage.toString())
                    const response = await fetch(`${baseRoute}/api/v1/stockItems/byfilters?${params.toString()}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': apiToken,
                        }
                    });

                    if (!response.ok) {
                        toast("Error fetch stock items", {
                            position: "top-center",
                            autoClose: 3000,
                            type: "error",
                        });
                        return
                    }

                    const resp = await response.json();
                    setStockItemsResp(resp);
                } catch (error) {
                    throw error
                }
            };
            fetchStockItemsData().catch((error) => {
                console.error("Error fetching stock items:", error);
            });
            setItemUUID(uuidv4())
        }
    }, [isTaskFieldsOpen, currentPage, values, originStockID, refresh, taskFields.route_id, apiToken, baseRoute]);

    // Обработчик добавления задачи
    const handleAddTask = async () => {
        try {
            const taskDate = new Date();
            const newTask = await addTask(taskDate);

            setTaskFields({
                task_id: newTask.task_id,
                task_num: newTask.task_num,
                route_id: '',
                route_name: '',
                task_date: taskDate,
                task_state: 'preparing',
                task_type: taskType
            });

            const updatedTasks = [...tasks, {
                task_id: newTask.task_id,
                task_num: newTask.task_num,
                route_id: '',
                route_name: '',
                task_date: taskDate,
                task_state: 'preparing',
                task_type: taskType
            }];

            setTasks(updatedTasks);
            setTaskFieldsOpen(true);
            setRouteChangeable(true)
            setDocsCreated(false);
            setTaskItemsResp([]);
            setOn(false)
            setPoNumFilter('')
            setPartNumFilter('')
        } catch (error) {
            console.log("Error adding task:", error);
        }
    };

    const addTask = async (taskDate) => {
        try {
            const response = await fetch(`${baseRoute}/api/v1/tasks`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': apiToken,
                },
                body: JSON.stringify({
                    task_date: taskDate,
                    task_state: 'preparing',
                    task_type: taskType,
                    idem_key: taskUUID,
                })
            });

            if (!response.ok) {
                if (response.status === 409) {
                    toast("Save this task to add a new one", {
                        position: "top-center",
                        autoClose: 3000,
                        type: "error",
                    });
                    return
                }
                toast("Error adding task, try again", {
                    position: "top-center",
                    autoClose: 3000,
                    type: "error",
                });
                return
            }
            return await response.json();
        } catch (error) {
            throw error;
        }
    };

    // Обработчик удаления задачи
    const handleDeleteTask = async (task_id) => {
        try {
            const deletedTask = await deleteTask(task_id);
            setTaskFieldsOpen(false)

            console.log(deletedTask);

            setTasks((prevTasks) => prevTasks.filter((task) => task.task_id !== task_id));
        } catch (error) {
            console.log("Error deleting task:", error);
        }
    };

    // Функция удаления задачи
    const deleteTask = async (taskId) => {
        try {
            const response = await fetch(`${baseRoute}/api/v1/tasks/${taskId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': apiToken,
                }
            });

            if (!response.ok) {
                toast("Error delete task, try again", {
                    position: "top-center",
                    autoClose: 3000,
                    type: "error",
                });
                return
            }

            return await response.json();
        } catch (error) {
            throw error;
        }
    };

    // Функция сохранения задачи
    const saveTask = async (routeID) => {
        try {
            const response = await fetch(`${baseRoute}/api/v1/tasks/${taskFields.task_id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': apiToken,
                },
                body: JSON.stringify({
                    task_date: taskFields.task_date,
                    route_id: routeID,
                    task_type: taskFields.task_type,
                    task_state: taskFields.task_state,
                    task_num: taskFields.task_num
                })
            });

            if (!response.ok) {
                toast("Error saving task, try again", {
                    position: "top-center",
                    autoClose: 3000,
                    type: "error",
                });
                return
            }
            return await response.json();
        } catch (error) {
            throw error;
        }
    };

    // Обработчик редактирования задачи
    const handleEditTask = async (task) => {
        try {
            setTaskFields((prevTaskFields) => ({
                ...prevTaskFields,
                task_id: task.task_id,
                task_num: task.task_num,
                route_id: task.route_id,
                route_name: task.route_name,
                task_date: task.task_date,
                task_state: task.task_state,
                task_type: task.task_type
            }));

            setItemUUID(uuidv4())

            if (task.route_id !== '') {
                setRouteChangeable(false);
                const foundRoute = routes.find(route => route.route_id === task.route_id);
                setOriginStockID(foundRoute.origin_stock_id);
            } else {
                setRouteChangeable(true);
            }
            await fetchDocs(task.task_id)
            setTaskFieldsOpen(true);
            setOn(false)
            setPoNumFilter('')
            setPartNumFilter('')
        } catch (error) {
            console.log("Error editing task:", error);
        }
    };

    const fetchDocs = async(taskId) => {
        try {
            const response = await fetch(`${baseRoute}/api/v1/docs/byTaskID/${taskId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': apiToken,
                }
            });

            if (!response.ok) {
                toast(`Failed fetch docs. error: ${response.status}`, {
                    position: "top-center",
                    autoClose: 3000,
                    type: "error",
                });
                return
            }


            const data = await response.json();
            let docs = data.docs
            let modifiedDocs = [...docs];

            if (docs.length > 0) {
                if (docs.length > 1) {
                    const baseDoc = JSON.parse(JSON.stringify(docs[0]));
                    baseDoc.po_num = 'BASE DOC';
                    baseDoc.order_id = 0;

                    modifiedDocs = [baseDoc, ...docs];
                }
                setDocsCreated(true);
                setTaskDocs(modifiedDocs);
            } else {
                setDocsCreated(false);
            }
        } catch (error) {
            throw error
        }
    }


    // Обработчик перехада в добавление элементов к задаче
    const handleClickAddItems = () => {
        try {
            if (taskFields.route_id !== ''){
                setRouteChangeable(false)
                const foundRoute = routes.find(route => route.route_id === taskFields.route_id);
                setOriginStockID(foundRoute.origin_stock_id);
            } else {
                toast("SELECT ROUTE", {
                    position: "top-center",
                    autoClose: 3000,
                    type: "info",
                });
            }
        } catch (error) {
            console.log(error);
        }

    };

    // Обработчик добавления товарных групп в задачу
    const handleAddItems = async() => {
        try {
            const addedItemsResp = await addTaskItems();
            console.log(addedItemsResp);
            await new Promise(resolve => setTimeout(resolve, 0));
            setSelectedItems([])
            setSelectedOrders([])
            setRefresh(!refresh)
        } catch (error) {
            console.log(error);
        }
    }

    // Функция сохранения задачи
    const addTaskItems = async () => {
        try {
            const response = await fetch(`${baseRoute}/api/v1/taskItems/batch`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': apiToken,
                },
                body: JSON.stringify({
                    task_id: taskFields.task_id,
                    items: selectedItems,
                    idem_key: itemUUID,
                })
            });

            if (!response.ok) {
                if (response.status === 409){
                    toast("wait until the previous request is completed", {
                        position: "top-center",
                        autoClose: 3000,
                        type: "error",
                    });
                    return
                } else {
                    toast("Error add items to task, try again", {
                        position: "top-center",
                        autoClose: 3000,
                        type: "error",
                    });
                    return
                }
            }

            return await response.json();
        } catch (error) {
            throw error;
        }
    };
    const handleUpdateItems = async() => {
        try {
            const updatedItemsResp = await updateTaskItems();
            console.log(updatedItemsResp);
            await new Promise(resolve => setTimeout(resolve, 0));
            setUpdatedItems([])
            setRefresh(!refresh)
            await fetchDocs(taskFields.task_id)
        } catch (error) {
            console.log(error);
        }
    }

    const updateTaskItems = async () => {
        try {
            const response = await fetch(`${baseRoute}/api/v1/taskItems/batch`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': apiToken,
                },
                body: JSON.stringify({
                    items: updatedItems,
                    idem_key: itemUUID
                })
            });

            if (!response.ok) {
                toast("Error add items to task, try again", {
                    position: "top-center",
                    autoClose: 3000,
                    type: "error",
                });
                return
            }

            return await response.json();
        } catch (error) {
            throw error;
        }
    };
    const [openDialog, setOpenDialog] = useState(false);

    const [tItemId, setTItemId] = useState('');
    const handleRemoveItem = async (taskItemID) => {
        try {
            if (taskFields.task_state === "complectating" || taskFields.task_state === "in_transit") {
                setOpenDialog(true);
                setTItemId(taskItemID);
            } else {
                await removeItem(taskItemID);
            }
        } catch (error) {
            console.log(error);
        }
    }


    const removeItem = async (taskItemID) => {
        const deletedItemResp = await deleteTaskItems(taskItemID);
        console.log(deletedItemResp);
        await new Promise(resolve => setTimeout(resolve, 0));
        setUpdatedItems((prevUpdatedItems) => prevUpdatedItems.filter((item) => item.id !== taskItemID));
        setRefresh(!refresh)
        await fetchDocs(taskFields.task_id)
    }

    const handleDialogClose = async (confirm) => {
        setOpenDialog(false);
        if (confirm) {
            await removeItem(tItemId);
        }
        setTItemId('')
    }

    const deleteTaskItems = async (taskItemID) => {
        try {
            const response = await fetch(`${baseRoute}/api/v1/taskItems/${taskItemID}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': apiToken,
                }
            });
            if (!response.ok) {
                toast("Error delete taskItem, try again", {
                    position: "top-center",
                    autoClose: 3000,
                    type: "error",
                });
                return
            }

            return await response.json()
        } catch (error) {
            throw error;
        }
    };



    const handleCreateDocs = async() => {
        try{
            setDocsEditorOpen(true)
            setTaskFieldsOpen(false)
            const createDocsResp = await createDocs(taskFields.task_id)
            console.log(createDocsResp)
            await fetchDocs(taskFields.task_id)
            setDocsCreated(true)
        } catch (error) {
            console.log("Error create docs:", error);
        }
    }

    const createDocs = async (taskId) => {
        try {
            const response = await fetch(`${baseRoute}/api/v1/docs`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': apiToken,
                },
                body: JSON.stringify({
                    task_id: taskId
                })
            });

            if (!response.ok) {
                toast("Error create docs, try again", {
                    position: "top-center",
                    autoClose: 3000,
                    type: "error",
                });
                return
            }

            return await response.json();
        } catch (error) {
            throw error;
        }
    }

    const handleEditDocs = async() => {
        setDocsEditorOpen(true)
        setTaskFieldsOpen(false)
    }


    const handleDocsFieldsChange = async(updatedDoc, orderId) => {
        try {
            if (orderId === 0) {
                const updatedTaskDocs = taskDocs.map(doc => ({ ...doc, po_date: updatedDoc.po_date }));

                setTaskDocs(updatedTaskDocs);

                for (const doc of updatedTaskDocs) {
                    if (doc.order_id === 0){
                        continue
                    }
                    const updateDocResp = await updateDoc(doc);
                    console.log(updateDocResp);
                }
            } else {
                const indexDoc = taskDocs.findIndex(doc => doc.order_id === orderId);
                if (indexDoc !== -1) {
                    const updatedTaskDocs = [...taskDocs]
                    updatedTaskDocs[indexDoc] = updatedDoc
                    setTaskDocs(updatedTaskDocs)
                }
                const updateDocResp = await updateDoc(updatedDoc)
                console.log(updateDocResp)
            }
        } catch (error) {
            console.log("Error update doc:", error);
        }
    }

    const handleApproveDocs = async() => {
        try{
            const approveDocResp = await approveDocs(taskFields.task_id)
            toast(approveDocResp.message, {
                position: "top-center",
                autoClose: 3000,
                type: "info",
            });
            setTaskFields((prevTaskFields) => ({
                ...prevTaskFields,
                task_state: 'complectating'
            }))
            setTaskDocs((prevTaskDocs) => (
                prevTaskDocs.map((taskDoc) => ({
                    ...taskDoc,
                    po_state: "approved"
                }))
            ))
        } catch (error){
            console.log("Error approve docs:", error)
        }

    }

    const approveDocs = async(taskId) => {
        try{
            const response = await fetch(`${baseRoute}/api/v1/docs/${taskId}/approve`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': apiToken,
                }
            });

            if (!response.ok) {
                toast("Error update doc", {
                    position: "top-center",
                    autoClose: 3000,
                    type: "error",
                });
                return
            }

            return await response.json();
        } catch(error) {
            throw error
        }
    }

    const updateDoc = async(updatedDoc) => {
        try{
            const response = await fetch(`${baseRoute}/api/v1/orders/${updatedDoc.order_id}/poInfo`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': apiToken,
                },
                body: JSON.stringify({
                    po_date: updatedDoc.po_date,
                    po_num: updatedDoc.po_num
                })
            });

            if (!response.ok) {
                if (response.status === 400) {
                    toast(`The order with the entered number(${updatedDoc.po_num}) already exists`, {
                        position: "top-center",
                        autoClose: 3000,
                        type: "error",
                    });
                    return
                }
                toast("Error update doc", {
                    position: "top-center",
                    autoClose: 3000,
                    type: "error",
                });
                return
            }
            return await response.json();
        } catch (error) {
            throw error
        }
    }
    const handleItemChange = async (editedItem, orderId, update) => {
        try {
            if (orderId === 0) {
                const updatedTaskDocs = [];

                for (const doc of taskDocs) {
                    const indexItem = doc.positions.findIndex((item) => item.item_id === editedItem.item_id);
                    if (indexItem !== -1) {
                        const updatedDoc = {
                            ...doc,
                            positions: [
                                ...doc.positions.slice(0, indexItem),
                                {
                                    ...doc.positions[indexItem],
                                    part_number: editedItem.part_number,
                                    manufacturer: editedItem.manufacturer,
                                    date_code: editedItem.date_code,
                                    part_description: editedItem.part_description,
                                    price: editedItem?.price,
                                    netto: editedItem?.netto,
                                    brutto: editedItem?.brutto,
                                    hs_code: editedItem.hs_code,
                                },
                                ...doc.positions.slice(indexItem + 1),
                            ],
                        };

                        if (update && doc.order_id !== 0) {
                            const updateProductResp = await updateProductLabel(doc.positions[indexItem]);
                            console.log(updateProductResp);
                        }

                        updatedTaskDocs.push(updatedDoc);
                    } else {
                        updatedTaskDocs.push(doc);
                    }
                }

                setTaskDocs(updatedTaskDocs);

            } else {
                const indexDoc = taskDocs.findIndex((doc) => doc.order_id === orderId);
                const indexItem = taskDocs[indexDoc].positions.findIndex((item) => item.product_id === editedItem.product_id);

                if (update && indexDoc !== -1 && indexItem !== -1) {
                    const updateProductResp = await updateProductLabel({ ...taskDocs[indexDoc].positions[indexItem], ...editedItem });
                    console.log(updateProductResp);
                }

                if (indexDoc !== -1 && indexItem !== -1) {
                    const updatedTaskDocs = [...taskDocs];
                    updatedTaskDocs[indexDoc].positions[indexItem] = { ...taskDocs[indexDoc].positions[indexItem], ...editedItem };
                    setTaskDocs(updatedTaskDocs);
                }
            }
        } catch (error) {
            console.log("Error update item:", error);
        }
    };



    const updateProductLabel = async(updatedProduct) => {
        try{
            const response = await fetch(`${baseRoute}/api/v1/products/${updatedProduct.product_id}/docInfo`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': apiToken,
                },
                body: JSON.stringify({
                    hs_code: updatedProduct.hs_code,
                    part_number: updatedProduct.part_number,
                    manufacturer: updatedProduct.manufacturer,
                    date_code: updatedProduct.date_code,
                    part_description: updatedProduct.part_description,
                    quantity: updatedProduct.quantity,
                    price: parseFloat(updatedProduct.price),
                    netto: parseFloat(updatedProduct.netto),
                    brutto: parseFloat(updatedProduct.brutto),
                })
            });

            if (!response.ok) {
                toast("Error update po item", {
                    position: "top-center",
                    autoClose: 3000,
                    type: "error",
                });
                return
            }

            return await response.json();
        } catch (error) {
            throw error
        }
    }

    const handleChangePriceCoef = async (value, orderId) => {
        try {
            const indexDoc = taskDocs.findIndex((doc) => doc.order_id === orderId);

            if (indexDoc !== -1) {
                const updatedTaskDocs = [...taskDocs];
                const coefficient = parseFloat(value);

                const baseDoc = updatedTaskDocs.find((doc) => doc.order_id === 0);

                for (const item of updatedTaskDocs[indexDoc].positions) {
                    const basePrice = baseDoc ? baseDoc.positions.find((baseItem) => baseItem.item_id === item.item_id)?.price : 0;
                    item.price = basePrice * coefficient;
                    const updateProductResp = await updateProductLabel(item);
                    console.log(updateProductResp);
                }

                setTaskDocs(updatedTaskDocs);
            }
        } catch (error) {
            console.log("Error change price coefficient:", error);
        }
    };

    const navigate = useNavigate()
    const handleComplectTask = (task) =>{
        setTaskFields((prevTaskFields) => ({
            ...prevTaskFields,
            task_id: task.task_id,
            task_num: task.task_num,
            route_id: task.route_id,
            route_name: task.route_name,
            task_date: task.task_date,
            task_state: task.task_state
        }));
        navigate(`/tasks/${task.task_num}/shipmentEditor`);
        localStorage.setItem('task_id', task.task_id);
        localStorage.setItem('task_date', task.task_date);
        localStorage.setItem('task_num', task.task_num);
        localStorage.setItem("task_type", task.task_type);
        setOn(false)
        setPoNumFilter('')
        setPartNumFilter('')
    }
    const handleShipAll = async() => {
        try{
            const response = await fetch(`${baseRoute}/api/v1/tasks/${taskFields.task_id}/approve`,{
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': apiToken,
                },
                body: JSON.stringify({
                    all: true
                })
            })
            if (!response.ok) {
                toast("Approve shipment error, try again", {
                    position: "top-center",
                    autoClose: 3000,
                    type: "error",
                });
            }
            toast("Shipment approved", {
                position: "top-center",
                autoClose: 3000,
                type: "info",
            });

            return await response.json()
        } catch (error) {
            throw error
        }
    }

    return (
        <Box>
            <Grid container spacing={5}>
                {/* Header */}
                <Grid item xs={12} sm={12} md={12}>
                    <Header
                        loggedIn={loggedIn}
                        onLogout={handleLogout}
                        isLoggedOut={isLoggedOut}
                    />
                </Grid>

                {/* Main Content */}
                <Grid item xs={12} sm={12} md={12}>
                    <Grid container spacing={3}>

                        <Grid item xs={12} sm={12} md={12} container justifyContent="flex-start">
                            <Collapse in={isTaskFieldsOpen || isDocsEditorOpen }>
                                <Button
                                    variant="outlined"
                                    color="success"
                                    startIcon={<ArrowBackIcon />}
                                    onClick={handleGoBack}
                                >
                                    BACK
                                </Button>
                            </Collapse>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                            <Container>
                                <Box  display="flex" justifyContent="space-between">
                                    <Collapse in={!isTaskFieldsOpen && !isDocsEditorOpen}>
                                        <Tabs value={taskTypeVal} onChange={handleTaskTypeChange}>
                                            <Tab label="INTERNAL" />
                                            <Tab label="EXTERNAL" />
                                        </Tabs>
                                    </Collapse>
                                </Box>
                            </Container>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Container>
                                <Box  display="flex" justifyContent="space-between">
                                    <Collapse in={!isTaskFieldsOpen && !isDocsEditorOpen}>
                                        <Tabs value={value} onChange={handleChange}>
                                            <Tab label="ALL" />
                                            <Tab label="PREPARING" />
                                            <Tab label="COMPLECTATING" />
                                            <Tab label="IN TRANSIT" />
                                            <Tab label="COMPLETED" />
                                        </Tabs>
                                    </Collapse>
                                    <Collapse in={!isTaskFieldsOpen && !isDocsEditorOpen}>
                                        <Button
                                            variant='outlined'
                                            color="success"
                                            id='taskFilterBtn'
                                            startIcon={<TuneIcon />}
                                            onClick={() => {
                                                setOn(p => !p)
                                                setPoNumFilter('')
                                                setPartNumFilter('')
                                            }}
                                            fullWidth
                                        >
                                            Filters
                                        </Button>
                                    </Collapse>
                                    <Collapse in={!isTaskFieldsOpen && !isDocsEditorOpen}>
                                        <Button
                                            variant='outlined'
                                            color="success"
                                            onClick={handleAddTask}
                                        >
                                            ADD TASK
                                        </Button>
                                    </Collapse>
                                </Box>
                            </Container>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Collapse in={!isTaskFieldsOpen && !isDocsEditorOpen}>
                                <Container>
                                    <Box  display="flex" justifyContent="space-between">
                                        <TextField
                                            autoFocus
                                            margin="dense"
                                            name="taskNumFilter"
                                            label="Task Number Filter"
                                            type="text"
                                            fullWidth
                                            value={taskNumFilter}
                                            onChange={handleFilterChange}
                                            autoComplete="off"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={() => setTaskNumFilter('')}>
                                                            <CloseIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Box>
                                </Container>
                            </Collapse>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            {on && (
                                <Container>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        name="poNumFilter"
                                        label="Po Number Filter"
                                        type="text"
                                        fullWidth
                                        value={poNumFilter}
                                        onChange={handleFilterChange}
                                        autoComplete="off"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={() => setPoNumFilter('')}>
                                                        <CloseIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        name="partNumFilter"
                                        label="Part Number Filter"
                                        type="text"
                                        fullWidth
                                        value={partNumFilter}
                                        onChange={handleFilterChange}
                                        autoComplete="off"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={() => setPartNumFilter('')}>
                                                        <CloseIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Container>
                            )}
                        </Grid>

                        {/* Task List */}
                        <Grid item xs={12} sm={12} md={12}>
                            <Collapse in={!isTaskFieldsOpen  && !isDocsEditorOpen }>
                                <Tasks
                                    tasks={tasks}
                                    onDeleteButtonClick={handleDeleteTask}
                                    onEditButtonClick={handleEditTask}
                                    onCompectButtonClick={handleComplectTask}
                                />
                            </Collapse>
                        </Grid>

                        {/* Task Fields */}
                        <Grid item xs={12} sm={12} md={12}>
                            <Collapse in={isTaskFieldsOpen}>
                                <TaskFields
                                    taskFields={taskFields}
                                    onTaskFieldsChange={handleTaskFieldsChange}
                                    routes={routes}
                                    onClickAddItems={handleClickAddItems}
                                    isRouteChangeable={isRouteChangeable}
                                    onDeleteButtonClick={handleDeleteTask}
                                    onEditDocsClick={handleEditDocs}
                                    onCreateDocsClick={handleCreateDocs}
                                    isDocsCreated={isDocsCreated}
                                    onApproveAllShipment={handleShipAll}
                                />
                            </Collapse>
                        </Grid>

                        {/* Add Items to Task */}
                        <Grid item xs={12} sm={12} md={12}>
                            <Collapse in={isTaskFieldsOpen && !isRouteChangeable}>
                                <AddItemsToTask
                                    stockItemsResp={stockItemsResp}
                                    taskItemsResp={taskItemsResp}
                                    values={values}
                                    setValues={setValues}
                                    handlePageChange={handlePageChange}
                                    currentPage={currentPage}
                                    selectedItems={selectedItems}
                                    setSelectedItems={setSelectedItems}
                                    handleAddItems={handleAddItems}
                                    handleUpdateItems={handleUpdateItems}
                                    handleRemoveItem={handleRemoveItem}
                                    updatedItems={updatedItems}
                                    setUpdatedItems={setUpdatedItems}
                                    currentTaskPage={currentTaskPage}
                                    handleTaskPageChange={handleTaskPageChange}
                                    partNumberFilter={partNumberFilter}
                                    setPartNumberFilter={setPartNumberFilter}
                                    selectedOrders={selectedOrders}
                                    setSelectedOrders={setSelectedOrders}
                                    taskFields={taskFields}
                                />
                            </Collapse>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} style={{ marginTop: '-50px' }}>
                            <Collapse in={isDocsEditorOpen && isDocsCreated}>
                                <DocsEditor
                                    taskDocs={taskDocs}
                                    onDocsFieldsChange={handleDocsFieldsChange}
                                    onDocItemChange={handleItemChange}
                                    handleApproveDocs={handleApproveDocs}
                                    onChangePriceCoef={handleChangePriceCoef}
                                />
                            </Collapse>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Dialog open={openDialog}>
                <DialogTitle>Delete Item</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">Are you sure you want to delete this item?</Typography>
                    <Typography variant="body1">You won't be able to add it again.</Typography>
                </DialogContent>
                <DialogActions>
                    <Box display="flex" justifyContent="space-between" width="100%">
                        <Button onClick={() => handleDialogClose(false)} color="success" variant="outlined">Cancel</Button>
                        <Button
                            onClick={() => handleDialogClose(true)}
                            variant="standard"
                            style={{ color: 'white', backgroundColor: 'red' }}
                        >
                            Delete
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
