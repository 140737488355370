import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider, useNavigate } from 'react-router-dom';
import StartPage from './components/StartPage';
import CheckOrderPage from './components/CheckOrderPage';
import LogInPage from './components/LogInPage';
import RoleRequestPage from './components/RoleRequestPage';
import ManageRolesPage from './components/ManageRolesPage';
import "./Styles/style.css";
import NotFoundPage from "./components/NotFoundPage";
import { ToastContainer } from "react-toastify";
import OrderDashBoardPage from "./components/OrderDashBoardPage";
import TaskPage from "./components/TaskPage";
import ShipmentEditor from "./components/ShipmentEditor";

const haronRoute = process.env.REACT_APP_HARON_ROUTE;

const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route path="/manageRoles" element={<PrivateRoute element={<ManageRolesPage />} />} />
            <Route path="/roleRequest" element={<PrivateRoute element={<RoleRequestPage />} />} />
            <Route path="/auth" element={<LogInPage />} />
            <Route path="/" element={<PrivateRoute element={<StartPage />} />} />
            <Route path="/Editor/:po_num" element={<PrivateRoute element={<CheckOrderPage />} />} />
            <Route path="/parcels" element={<PrivateRoute element={<OrderDashBoardPage />} />} />
            <Route path="/tasks" element={<PrivateRoute element={<TaskPage />} />} />
            <Route path="/tasks/:taskNum/shipmentEditor" element={<PrivateRoute element={<ShipmentEditor />} />} />
            <Route path="/Editor/not-found" element={<NotFoundPage />} />
        </>
    )
);

function isTokenExpired(token) {
    try {
        const payload = JSON.parse(atob(token.split('.')[1]));
        return payload.exp < Math.floor(Date.now() / 1000);
    } catch (error) {
        console.error("Invalid token format", error);
        return true;
    }
}

async function refreshToken() {
    try {
        const response = await fetch(`${haronRoute}/api/v1/refresh`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ refresh_token: localStorage.getItem('refresh_token') })
        });

        if (!response.ok) {
            throw new Error(`Failed to refresh token: ${response.status}`);
        }

        const data = await response.json();
        if (data.access_token) {
            localStorage.setItem('token', 'Bearer ' + data.access_token);
            return data.access_token;
        }
        throw new Error('Token refresh response did not include a new token');
    } catch (error) {
        console.error("Error refreshing token:", error);
        return null;
    }
}

function PrivateRoute({ element }) {
    const navigate = useNavigate();

    useEffect(() => {
        const checkAuth = async () => {
            const token = localStorage.getItem('token');
            if (!token || isTokenExpired(token)) {
                const newToken = await refreshToken();
                if (!newToken) {
                    navigate('/auth');
                }
            }
        };

        checkAuth();
    }, [navigate]);

    return element;
}

createRoot(document.getElementById('root')).render(
    <>
        <RouterProvider router={router} />
        <ToastContainer />
    </>
);
