import React, {useEffect, useState} from 'react'
import {
    TableRow,
    TableCell,
    Dialog,
    DialogTitle,
    DialogContent,
    Table,
    TableBody,
    TableHead, Typography, TableContainer, Button, MenuItem, Select
} from '@mui/material'
import {toast} from "react-toastify"

export default function SingleRow({ track }) {
    const baseRoute = process.env.REACT_APP_BASE_ROUTE
    const api_token = localStorage.getItem('token')
    const [selectedTracking, setSelectedTracking] = useState(null)
    const [status, setStatus] = useState("")

    useEffect(() => {
        setStatus(track.status)
        setStatusColor(getStatusColor(track.status))
    }   , [track])

    const handleTrackingClick = (tracking) => {
        setSelectedTracking(tracking)
    }

    const handleCloseDialog = () => {
        setSelectedTracking(null)
    }

    const updateStatus = async(track_id, newStatus) => {
        try {
            const response = await fetch(`${baseRoute}/api/v1/orders/${track.order.order_id}/tracking`,{
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': api_token,
                },
                body: JSON.stringify({
                    tracking_id: track_id,
                    state: newStatus,
                })
            })

            if (!response.ok) {
                toast("Error update tracking status, try again", {
                    position: "top-center",
                    autoClose: 3000,
                    type: "error",
                })
            }
            setStatus(newStatus)
            setStatusColor(getStatusColor(newStatus));
            return await response.json()
        }
        catch (error){
            console.log(error)
        }
    }


    const getStatusColor = (status) => {
        switch (status) {
            case 'in_transit':
                return { backgroundColor: 'cornflowerblue' } // сине-сереневый цвет
            case 'delivered':
                return { backgroundColor: 'lightgreen' } // мягкий зелёный
            case 'escalation':
                return { backgroundColor: 'lightcoral' } // светлый бардовый
            case 'cancelled':
                return { backgroundColor: 'mediumorchid' } // средний оттенок фиолетового
            default:
                return { backgroundColor: 'white' }
        }
    }
    const [statusColor, setStatusColor] = useState(getStatusColor(track?.status));

    return (
        <>
            <TableRow>
                <TableCell>
                    {track?.order?.po_num}
                </TableCell>
                <TableCell>
                    {track?.order?.po_date ? new Date(track.order.po_date).toLocaleDateString('en-GB') : 'unknown'}
                </TableCell>
                <TableCell>
                    {track?.order?.supplier}
                </TableCell>
                <TableCell>
                    <Button onClick={() => handleTrackingClick(track)}>
                        {track?.track_num || 'unknown'}
                    </Button>
                </TableCell>
                <TableCell>
                    {track?.delivery_date ? new Date(track.delivery_date).toLocaleDateString('en-GB') : 'unknown'}
                </TableCell>
                <TableCell>
                    {track?.last_update_date ? (new Date(track.last_update_date).toISOString() !== '0001-01-01T00:00:00.000Z' ? new Date(track.last_update_date).toLocaleDateString('en-GB') : 'unknown') : 'unknown'}
                </TableCell>
                <TableCell style={{ backgroundColor: statusColor.backgroundColor }}>
                    <Select value={status} onChange={(event) => updateStatus(track.track_id, event.target.value)}>
                        <MenuItem value="escalation">Escalation</MenuItem>
                        <MenuItem value="in_transit">In Transit</MenuItem>
                        <MenuItem value="delivered">Delivered</MenuItem>
                        <MenuItem value="cancelled">Cancelled</MenuItem>
                    </Select>
                </TableCell>
            </TableRow>

            {selectedTracking && (
                <Dialog open={Boolean(selectedTracking)} onClose={handleCloseDialog}>
                    <DialogTitle>Details for Tracking: {selectedTracking.track_num}</DialogTitle>
                    <DialogContent>
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Delivery Date:</TableCell>
                                        <TableCell>{selectedTracking?.delivery_date ? new Date(selectedTracking.delivery_date).toLocaleDateString('en-GB') : 'N/A'}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Status:</TableCell>
                                        <TableCell>{selectedTracking.status}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Typography variant="subtitle1"><b>Positions for tracking:</b></Typography>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>

                                        </TableCell>
                                        <TableCell>
                                            Part Number
                                        </TableCell>
                                        <TableCell>
                                            Quantity
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {selectedTracking.positions &&
                                        selectedTracking.positions.map((position, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{index+1}</TableCell>
                                                <TableCell>{position.part_number}</TableCell>
                                                <TableCell>{position.quantity}</TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContent>
                </Dialog>
            )}
        </>
    )
}
