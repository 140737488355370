import { Box, Button, Grid, Paper, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../Header';
import "./styles/style.css";

function AuthForm({ isRegister, username, password, confirmPassword, setUsername, setPassword, setConfirmPassword, handleSubmit, errorText, passwordMismatch }) {
    return (
        <Grid container spacing={3} direction="column" justifyContent="center" alignItems="center">
            <Grid item>
                <Typography variant="h6">{isRegister ? "QCHECK 3 SIGN UP" : "QCHECK 3"}</Typography>
            </Grid>
            <Grid item>
                <TextField
                    variant='filled'
                    label='Username'
                    size='small'
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    error={Boolean(errorText)}
                    className={errorText ? 'errorField' : ''}
                    fullWidth
                />
            </Grid>
            <Grid item>
                <TextField
                    variant='filled'
                    label='Password'
                    type='password'
                    size='small'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    error={Boolean(errorText)}
                    className={errorText ? 'errorField' : ''}
                    fullWidth
                />
            </Grid>
            {isRegister && (
                <Grid item>
                    <TextField
                        variant='filled'
                        label='Confirm Password'
                        type='password'
                        size='small'
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        error={passwordMismatch}
                        className={passwordMismatch ? 'errorField' : ''}
                        fullWidth
                    />
                </Grid>
            )}
            {errorText && (
                <Grid item className="errorText">
                    {errorText}
                </Grid>
            )}
            <Grid item>
                <Button variant='contained' color='success' fullWidth onClick={handleSubmit} disabled={isRegister && passwordMismatch}>
                    {isRegister ? "Sign Up" : "Log In"}
                </Button>
            </Grid>
        </Grid>
    );
}

export default function LogInPage() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isRegister, setIsRegister] = useState(false);
    const [errorText, setErrorText] = useState('');

    const navigate = useNavigate();
    const haronRoute = process.env.REACT_APP_HARON_ROUTE;
    const baseRoute = process.env.REACT_APP_BASE_ROUTE;

    const passwordMismatch = isRegister && password !== confirmPassword;

    const handleAuth = async () => {
        try {
            const url = isRegister ? `${baseRoute}/api/v1/register` : `${haronRoute}/api/v1/login`;
            const response = await fetch(url, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ username, password })
            });

            if (response.ok) {
                if (!isRegister) {
                    const authData = await response.json();
                    localStorage.setItem('token', `Bearer ${authData.access_token}`);
                    localStorage.setItem('refresh_token', `${authData.refresh_token}`);
                    navigate('/');
                } else {
                    setIsRegister(false);
                    setUsername('');
                    setPassword('');
                    setConfirmPassword('');
                }
            } else {
                const errorData = await response.json();
                setErrorText(errorData.message);
            }
        } catch (error) {
            console.error('Authentication error:', error);
            setErrorText('An unexpected error occurred.');
        }
    };

    return (
        <Box>
            <Grid container spacing={9} justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                    <Header loggedIn={false} showRegisterForm={isRegister} onToggleForm={() => setIsRegister(!isRegister)} />
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <Paper elevation={12}>
                        <AuthForm
                            isRegister={isRegister}
                            username={username}
                            password={password}
                            confirmPassword={confirmPassword}
                            setUsername={setUsername}
                            setPassword={setPassword}
                            setConfirmPassword={setConfirmPassword}
                            handleSubmit={handleAuth}
                            errorText={errorText}
                            passwordMismatch={passwordMismatch}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
}